<template>
  <div class="chart-container-box pr">
    <div
      class="jcsb mb10"
      style="justify-content: space-evenly; color: #dad4d4"
    >
      <div class="text">{{ chartRefObj[chartRef] }}</div>
      <div class="text">{{ value }}</div>
      <div class="text" style="color: #4bf48b;">{{minValue}}</div>
      <div class="text" style="color: red;">{{maxValue}}</div>
    </div>
    <div :ref="chartRef" class="chart h80"></div>
  </div>
</template>

<script>
const echarts = require("echarts");
export default {
  data() {
    return {
      chart: null,
      value: 0,
      minValue: 0,
      maxValue: 0,
      option: {
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "#fff", // 将颜色设置为您需要的值
          },
        },
        series: [
          {
            data: [],
            type: "line",
            smooth: true,
          },
        ],
      },
      chartRefObj: {
        device_water_heigh_dom: "水面距离(m)",
        device_water_speed_dom: "水面流速(m/s)",
        waterLevel_dom: "水位(m)",
      },
      chartData: [],
    };
  },
  props: {
    chartRef: {
      type: String,
      default: "chartRef",
    },
    airData: {
      type: String | Number,
      default: 0,
    },
  },
  mounted() {
    this.initChart();
    this.time = setInterval(() => {
      if (this.airData >= 0) {
        this.updateData(this.airData);
        this.chartData.push(this.airData);
        // 将数据转换为数值类型
        const numericData = this.chartData.map(Number);
        // 获取最大值
        const maxValue = Math.max(...numericData);
        this.maxValue = maxValue;
        // 获取最小值
        const minValue = Math.min(...numericData);
        this.minValue = minValue;
      }
    }, 1000);
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs[this.chartRef]);
      this.chart.setOption(this.option);
    },
    updateData(value) {
      const now = new Date().getTime();
      this.value = value;
      this.option.series[0].data.push([now, value]);
      this.chart.setOption(this.option);
    },
  },
};
</script>

<style scoped lang="scss">
.chart-container-box {
  width: 100%;
  // border: 1px solid #3bc1e5;
  .text {
    text-align: center;
    width: 105px;
  }
}
</style>
